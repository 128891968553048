import { NUM_CELLS } from './constants';
import ReconnectingWebSocket from 'reconnecting-websocket';

const websocketUrl = process.env.REACT_APP_WS_URL;

type CharUpdate = {
    type: 1,
    index: number,
    char: number,
    data: number,
}

type CharChunkUpdate = {
    type: 2,
    index: number,
    // Buffer containing alternately char/charData
    charsBuffer: Uint16Array,
}

type SlowDown = {
    type: 3,
}

type CountUpdate = {
    type: 4,
    count: number,
}


export class WebSocketClient {
    private websocket: ReconnectingWebSocket;

    public get isOpen() {
        return this.websocket?.readyState === WebSocket.OPEN;
    }

    constructor(
        onOpen: () => any,
        onCharUpdate: (index: number, char: number, data: number) => any,
        onCharChunkUpdate: (index: number, charsBuffer: Uint16Array) => any,
        onSlowDown: () => any,
        onCountUpdate: (count: number) => any,
    ) {
        if (!websocketUrl) {
            throw new Error('WebSocket URL is not defined');
        }

        this.websocket = new ReconnectingWebSocket(websocketUrl);
        this.websocket.binaryType = 'arraybuffer';

        this.websocket.onopen = () => {
            console.log('WebSocket connected');
            onOpen();
        };

        this.websocket.onclose = () => {
            console.log('WebSocket connection closed');
        };

        this.websocket.onmessage = (event) => {
            const msg = this.parseWSBuffer(event.data);
            // console.log('Recieved', msg);
            if (msg.type === 1) {
                onCharUpdate(msg.index, msg.char, msg.data);
            }
            else if (msg.type === 2) {
                onCharChunkUpdate(msg.index, msg.charsBuffer);
            }
            else if (msg.type === 3) {
                onSlowDown();
            }
            else if (msg.type === 4) {
                onCountUpdate(msg.count);
            }
        };

        this.websocket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    }

    // Send a message through the WebSocket
    public sendMessage(buffer: Uint8Array) {
        if (!this.isOpen) {
            throw new Error('WebSocket not open');
        }
        this.websocket.send(buffer);
    }

    // Close the WebSocket connection
    public close() {
        if (this.websocket) {
            this.websocket.close();
        }
    }

    private parseWSBuffer(buffer: ArrayBuffer): CharUpdate | CharChunkUpdate | SlowDown | CountUpdate {
        const bytes = new Uint8Array(buffer);

        const type = bytes[0];

        if (type === 1) {
            const index = (bytes[1] << 16) | (bytes[2] << 8) | bytes[3];
            const char = bytes[4];
            const data = bytes[5];
            return {
                type: 1,
                index,
                char,
                data,
            };
        } else if (type === 2) {
            const index = (bytes[1] << 16) | (bytes[2] << 8) | bytes[3];
            const chars = bytes.slice(4);

            // HACK: Replace any 0s as spaces (only in char position)
            for (var i = 1; i < chars.length; i += 2) {
                if (chars[i] === 0) chars[i] = 32;
            }

            // Convert Uint8Array to Uint16Array by treating pairs of bytes as 16-bit values
            const charsBuffer = new Uint16Array(chars.buffer);
            return {
                type: 2,
                index,
                charsBuffer,
            };
        }
        else if (type === 3) {
            return {
                type: 3,
            };
        }
        else if (type === 4) {
            const count = (bytes[1] << 16) | (bytes[2] << 8) | bytes[3];
            return {
                type: 4,
                count,
            }
        }
        else {
            throw new Error(`Could not parse message w/ unknown type: ${type} ${bytes} len ${bytes.length}`);
        }
    }

    public sendBufferChange(boardIx: number, characterAsciiIx: number, charData: number) {
        const buffer = this.encodeUpdateMsg(boardIx, characterAsciiIx, charData);
        if (buffer) {
            this.sendMessage(buffer);
        }
    }

    // Encode the update message for the server
    private encodeUpdateMsg(boardIx: number, characterAsciiIx: number, charData: number): Uint8Array | null {
        if (characterAsciiIx < 0 || characterAsciiIx >= 128
            || charData < 0 || charData >= 256
            || boardIx < 0 || boardIx >= NUM_CELLS)
        {
            console.log('ERROR: Invalid update msg', boardIx, characterAsciiIx);
            return null;
        }

        const buffer = new Uint8Array(6);
        buffer[0] = 1;
        buffer[1] = (boardIx >> 16) & 0xff;
        buffer[2] = (boardIx >> 8) & 0xff;
        buffer[3] = boardIx & 0xff;
        buffer[4] = characterAsciiIx;
        buffer[5] = charData;
        return buffer;
    }

    public sendViewRangeChange(startIx: number, endIx: number) {
        const buffer = this.encodeWatchMsg(startIx, endIx);
        // console.log('Sending sync range', startIx, endIx);
        if (buffer) {
            this.sendMessage(buffer);
        }
    }

    // Encode the watch message for the server
    private encodeWatchMsg(startIx: number, endIx: number): Uint8Array | null {
        if (startIx < 0 || startIx >= NUM_CELLS || endIx < 0 || endIx >= NUM_CELLS) {
            console.log('ERROR: Invalid watch msg', startIx, endIx);
            return null;
        }

        const buffer = new Uint8Array(7);
        buffer[0] = 2;
        buffer[1] = (startIx >> 16) & 0xff;
        buffer[2] = (startIx >> 8) & 0xff;
        buffer[3] = startIx & 0xff;
        buffer[4] = (endIx >> 16) & 0xff;
        buffer[5] = (endIx >> 8) & 0xff;
        buffer[6] = endIx & 0xff;
        return buffer;
    }

}
