import React, { memo, useState, useEffect, useRef } from 'react';
import { FixedSizeGrid as Grid, areEqual, GridChildComponentProps } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

import TextStyler, { getTextStyle } from './TextStyler';
import GoToCellUI from './GoToCell';
import { useCharBoard, BoardState } from "./board";
import { WebSocketClient } from "./websocket";
import { NUM_CELLS } from './constants';
import "./App.css";

const CELL_W = 10;
const CELL_H = 19;

// Sync this number extra cells to server,
// which are outside of current draw range.
const SYNC_RANGE_OFFSET = 1000;

interface CellData {
    board: BoardState;
    cursorIx: number;
    onClick: (ix: number) => void;
    numCols: number;
}

const getCell = (board: BoardState, ix: number): number => {
    ix = ix - board.boardOffset;
    if (ix < board.board.length && ix >= 0) {
        return board.board[ix];
    }
    // Out of bounds, return empty cell.
    return 32 << 8;
}


interface ArePropsEqualParams extends GridChildComponentProps<CellData> {}

// Check if we should rerender the cell
function arePropsEqual(pr1: ArePropsEqualParams, pr2: ArePropsEqualParams): boolean {
    let ix1 = pr1.columnIndex + pr1.rowIndex * pr1.data.numCols;
    let ix2 = pr2.columnIndex + pr2.rowIndex * pr2.data.numCols;

    return (
        ix1 === ix2 &&
        getCell(pr1.data.board, ix1) === getCell(pr2.data.board, ix2) &&
        (pr1.data.cursorIx === ix1) === (pr2.data.cursorIx === ix2) && // Hasn't been selected/deselected
        pr1.columnIndex === pr2.columnIndex &&
        pr1.rowIndex === pr2.rowIndex &&
        areEqual(pr1.style, pr2.style)
    );
}

const Cell: React.FC<GridChildComponentProps<CellData>> = memo(({ data, columnIndex, rowIndex, style }) => {
    const { board, cursorIx, onClick, numCols } = data;
    let ix = columnIndex + rowIndex * numCols;

    if (ix >= NUM_CELLS) {
        return <div className={"CellNull"} style={style}></div>
    }

    let isEven = rowIndex % 2 === 0;
    let cl = ix === cursorIx ? 'CellSelected' : isEven ? "CellOdd" : "CellEven";

    let cell = getCell(board, ix);
    let char = String.fromCharCode(cell >> 8);
    let textStyle = getTextStyle(cell & 0xff);

    return (
        <div
            className={cl}
            onClick={() => onClick(ix)}
            style={style}
            key={ix}
        >
            <span style={textStyle}>{char}</span>
        </div>
    );
}, arePropsEqual);

interface ListViewProps {
    board: BoardState;
    cursorIx: number;
    onClick: (ix: number) => void;
    viewRangeChanged: (start: number, end: number) => void;
}

const ListView: React.FC<ListViewProps> = ({ board, cursorIx, onClick, viewRangeChanged }) => (
    <AutoSizer>
        {({ height, width }: { height: number; width: number }) => {
            const gridRef = React.createRef<Grid>();

            let numCols = Math.floor(width / CELL_W);
            let gridWidth = CELL_W * numCols + 2;
            let numRows = Math.ceil(NUM_CELLS / numCols);

            const goToCell = (cellIx: number) => {
                console.log('go to cell!', cellIx);
                // Select cell by 'clicking' on it.
                onClick(cellIx);

                gridRef.current?.scrollToItem({
                    align: 'center',
                    rowIndex: Math.floor(cellIx / numCols)
                });
            }

            return (
                <React.Fragment>
                    <GoToCellUI onClick={goToCell}/>
                    <Grid
                        ref={gridRef}
                        columnCount={numCols}
                        columnWidth={CELL_W}
                        height={height}
                        rowCount={numRows}
                        rowHeight={CELL_H}
                        width={gridWidth}
                        className="Grid"
                        itemData={{ board, cursorIx, numCols, onClick }}
                        onItemsRendered={({ overscanRowStartIndex, overscanRowStopIndex }) =>
                            viewRangeChanged(
                                overscanRowStartIndex * numCols,           // Start ix
                                (overscanRowStopIndex + 1) * numCols - 1,  // End ix (inclusive)
                        )}
                    >
                        {Cell}
                    </Grid>
                </React.Fragment>
            );
        }}
    </AutoSizer>
);


const Title: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
            {/* Render the button and add the click handler */}

            <div className="title-box">
                <h1>
                    <span>One Million Letters</span>
                    <button onClick={toggleVisibility} className="info-button">
                        i
                    </button>
                </h1>
            </div>

            {/* The div whose visibility is toggled */}
            {isVisible && (
                <div style={{ marginBottom: '10px', padding: '20px', border: '1px solid #ccc' }}>
                    <p>
                        An experiment(?) by <a href="https://x.com/onemoremoose">Oisin Carroll</a>.
                        <a href="https://buymeacoffee.com/carrollgames/"><svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon-tabler"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 14c.83 .642 2.077 1.017 3.5 1c1.423 .017 2.67 -.358 3.5 -1c.83 -.642 2.077 -1.017 3.5 -1c1.423 -.017 2.67 .358 3.5 1" /><path d="M8 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2" /><path d="M12 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2" /><path d="M3 10h14v5a6 6 0 0 1 -6 6h-2a6 6 0 0 1 -6 -6v-5z" /><path d="M16.746 16.726a3 3 0 1 0 .252 -5.555" /></svg></a>
                        <a href="https://imois.in/"><svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon-tabler"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /><path d="M7 8h10" /><path d="M7 12h10" /><path d="M7 16h10" /></svg></a>
                        <a href="https://travle.earth/"><svg xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon-tabler"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8 16l2 -6l6 -2l-2 6l-6 2" /><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 3l0 2" /><path d="M12 19l0 2" /><path d="M3 12l2 0" /><path d="M19 12l2 0" /></svg></a>
                    </p>
                    <br/>
                    <p>
                        When you make a change, it'll be shown to everyone in real time. Write a secret, a story, a meme. Just, be nice!
                    </p>
                    <br/>
                    <p>
                        onemillionletters.net is inspired by <a href="https://onemillioncheckboxes.com">onemillioncheckboxes.com</a>
                    </p>
                    <br/>
                    <p>
                        UPDATE: 17:10GMT - Added rate limiting on the server side so bots can't just mass delete pieces of text.
                    </p>
                </div>
            )}
        </div>
    );
}

const App: React.FC = () => {
    const {
        boardState,
        trySetCell,
        setBoardState,
    } = useCharBoard();

    const [syncRange, setSyncRange] = useState<[number, number]>([0, 0]);

    const [filledChars, setFilledChars] = useState<number>(0);

    // Syncronized ref to syncRange
    const syncRangeRef = useRef(syncRange);
    useEffect(() => {
        syncRangeRef.current = syncRange;
    }, [syncRange]);

    // Ref for trySetCell
    const trySetCellRef = useRef(trySetCell);
    useEffect(() => {
        trySetCellRef.current = trySetCell;
    }, [trySetCell]);

    // Ix of selected cell (ready to type)
    const [cursorIx, setCursorIx] = useState(0);
    // [Maybe unnecessary] Trick to keep a variable always equal to setCursorIx
    const cursorIxRef = useRef(cursorIx);
    useEffect(() => {
        cursorIxRef.current = cursorIx;
    }, [cursorIx]);

    const inputRef = useRef<HTMLInputElement>(null);

    const [currentStyle, setCurrentStyle] = useState(0);

    const websocketClient = useRef<WebSocketClient | null>(null);
    useEffect(() => {
        // When we recieve updates on the websocket, apply them to the boardstate!
        websocketClient.current = new WebSocketClient(
            // onConnect, send current sync range.
            () => { websocketClient.current?.sendViewRangeChange(syncRangeRef.current[0], syncRangeRef.current[1]); },
            // onCharUpdate
            (index: number, char: number, data: number) => trySetCellRef.current(index, char, data),
            // onCharChunkUpdate
            (index: number, chars: Uint16Array) => setBoardState({boardOffset: index, board: chars}),
            // onSlowDown
            () => {
                alert('Server says slow down! Try again in 60 seconds.');
                console.log('Slow down!');
            },
            // onCountUpdate
            (count: number) => {
                setFilledChars(count);
            }
        );
        return () => {
            websocketClient.current?.close();
        };
    }, []);

    const viewRangeChanged = (start: number, end: number) => {
        // Check if we should update syncRange!
        var [ syncStart, syncEnd ] = syncRange;

        // Sync range should contain start/end
        if (start < syncStart || end > syncEnd) {
            start = Math.max(start - SYNC_RANGE_OFFSET, 0);
            end = Math.min(end + SYNC_RANGE_OFFSET, NUM_CELLS-1);
            setSyncRange([start, end]);

            // If this is connected, then send the update.
            // Otherwise this'll be sent when we connect anyway.
            if (websocketClient.current?.isOpen){
                websocketClient.current?.sendViewRangeChange(start, end);
            }
        }
    };

    const onClickCell = (ix: number) => {
        setCursorIx(ix);
        inputRef.current?.focus();
    };

    // Stop spamming!!
    const [lastChar, setLastChar] = useState<number>(-1);
    const [pressCount, setPressCount] = useState<number>(0);

    let checkSpam = (asciiChar: number) => {
        if (lastChar === asciiChar) {
            setPressCount((c) => c + 1);
        }
        else {
            setLastChar(asciiChar);
            setPressCount(0);
        }
        if (pressCount > 50) {
            setPressCount(0);
            return false;
        }
        return true;
    }

    const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        if (value.length < 2) {
            // Check if spamming!
            if (checkSpam(32)) {
                // Backspace (clear style, too)
                if (trySetCell(cursorIxRef.current - 1, 32, 0)) {
                    websocketClient.current?.sendBufferChange(cursorIxRef.current - 1, 32, 0);
                }
                setCursorIx((ix) => ix - 1);
            }
            else {
                alert("Calm down!");
            }
        } else if (value.length === 3) {
            const key = value.slice(-1);
            const asciicode = key.charCodeAt(0);

            // Check if spamming!
            if (checkSpam(asciicode)) {
                if (trySetCell(cursorIxRef.current, asciicode, currentStyle)) {
                    websocketClient.current?.sendBufferChange(cursorIxRef.current, asciicode, currentStyle);
                }
                setCursorIx((ix) => ix + 1);
            }
            else {
                alert("Calm down!");
            }
        }

        inputRef.current!.value = 'ZZ';
        // Trick to move cursor to end of input (affects android chrome)
        setTimeout(() => inputRef.current?.setSelectionRange(2, 2), 0);
    };

    // Temp test spamming a character! Note: This should use useeffect and be disabled etc - rn
    // multiple intervals will be created...
    /* const intervalId = setInterval(() => {
     *     websocketClient.current?.sendBufferChange(cursorIxRef.current - 1, 32, 0);
     * }, 50);
     */

    const handleLoseFocus = () => {
        setCursorIx(-1);
    };

    return (
        <div className="container">
            <Title />
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '7px',
            }}>{filledChars}/1000000</div>
            <TextStyler
                data={currentStyle}
                setData={setCurrentStyle}/>
            <input
                ref={inputRef}
                style={{
                    position: 'absolute',
                    opacity: 0.01,
                    height: '1px',
                    width: '1px',
                    border: 'none',
                }}
                defaultValue="ZZ"
                onInput={handleInput}
                onBlur={handleLoseFocus}
                autoFocus
            />
            <div className="list-box">
                <ListView
                    board={boardState}
                    cursorIx={cursorIx}
                    onClick={onClickCell}
                    viewRangeChanged={viewRangeChanged}
                />
            </div>
        </div>
    );
}

export default App;
