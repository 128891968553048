import React from 'react';

// Define the available options
const colors = ['#000000', '#CCCCCC', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF'];

interface TextStylerProps {
  data: number;
  setData: (data: number) => void;
}

export function getTextStyle(data: number) {
    return {
          color: colors[data >> 4],
          fontWeight: data & 0x8 ? 'bold' : 'normal',
          fontStyle: data & 0x4 ? 'italic' : 'normal',
          textDecoration: `${data & 0x1 ? 'line-through ' : ''}${data & 0x2 ? 'underline' : ''}`,
    }
}

const TextStyler: React.FC<TextStylerProps> = ({data, setData}) => {
    // data is 8 bytes!
    // 0000  0      0         0           0
    // color isBold isItalic  isUnderline isStrikethrough

    const getColor = () => data >> 4;
    const setColor = (ix: number) => {
        setData((ix << 4) | (data & 0xf));
    };

    const getBit = (ix: number): boolean => ((data >> ix) & 1) === 1;
    const toggleBit = (ix: number) => {
        setData(data ^ (1 << ix));
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '16px', marginBottom: '10px' }}>
            <div style={{ display: 'flex', gap: '8px' }}>
                {colors.map((c, ix) => (
                    <div
                        key={c}
                        onClick={() => setColor(ix)}
                        className='TextButton'
                        style={{
                            backgroundColor: c,
                            border: ix === getColor() ? '3px solid #4f4f4f' : '1px solid #ccc',
                        }}
                    />
                ))}
            </div>

            <div style={{ display: 'flex', gap: '8px', fontFamily: 'monospace, monospace' }}>
                <button
                    onClick={() => toggleBit(3)}
                    className='TextButton'
                    style={{
                        fontWeight: getBit(3) ? 'bold' : 'normal',
                        backgroundColor: getBit(3) ? '#ddd' : 'transparent',
                    }}
                >
                    B
                </button>
                <button
                    onClick={() => toggleBit(2)}
                    className='TextButton'
                    style={{
                        fontStyle: getBit(2) ? 'italic' : 'normal',
                        backgroundColor: getBit(2) ? '#ddd' : 'transparent',
                    }}
                >
                    I
                </button>
                <button
                    onClick={() => toggleBit(1)}
                    className='TextButton'
                    style={{
                        textDecoration: getBit(1) ? 'underline' : 'none',
                        backgroundColor: getBit(1) ? '#ddd' : 'transparent',
                    }}
                >
                    U
                </button>
                <button
                    onClick={() => toggleBit(0)}
                    className='TextButton'
                    style={{
                        textDecoration: getBit(0) ? 'line-through' : 'none',
                        backgroundColor: getBit(0) ? '#ddd' : 'transparent',
                    }}
                >
                    S
                </button>
            </div>

            {/* Preview */}
            {/* <div
                style={{
                marginTop: '30px',
                padding: '20px',
                backgroundColor: '#f5f5f5',
                color,
                fontWeight: isBold ? 'bold' : 'normal',
                fontStyle: isItalic ? 'italic' : 'normal',
                fontSize
                }}
                >
                This is a preview of your styled text.
                </div> */}
        </div>
    );
};

export default TextStyler;
