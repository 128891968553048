import React, { useState } from 'react';
import './App.css';

interface GoToCellUIProps {
    onClick: (value: number) => void;
}

const GoToCellUI: React.FC<GoToCellUIProps> = ({ onClick }) => {
    const [inputValue, setInputValue] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleClick = () => {
        const numericValue = parseFloat(inputValue);
        if (!isNaN(numericValue)) {
            onClick(numericValue);
        } else {
            alert('Please enter a valid number');
        }
    };

    return (
        <div className="gotocell-ui">
            <input
                type="number"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Cell number"
                min='1'
                max='1000000'
            />
            <button onClick={handleClick}>Go</button>
        </div>
    );
};

export default GoToCellUI;
