import { useState, useMemo, useCallback } from 'react';

export interface BoardState {
    boardOffset: number;
    board: Uint16Array;
}

export function useCharBoard() {
    const [boardOffset, setBoardOffset] = useState<number>(0);
    const [board, setBoard] = useState<Uint16Array>(new Uint16Array());

    // Combined state containing both boardOffset and board
    const boardState = useMemo<BoardState>(() => {
        return { boardOffset, board };
    }, [boardOffset, board]);

    const trySetCell = useCallback((ix: number, ascii: number, data: number): boolean => {
        ix -= boardOffset;

        if (ix < 0 || ix >= board.length || ascii < 0 || ascii >= 128 || data < 0 || data >= 256) {
            return false;
        }
        const cell = (ascii << 8) | data;
        // No update needed
        if (board[ix] === cell) {
            return false;
        }
        setBoard((prevBoard) => {
            const newBoard = prevBoard.slice(); // Duplicate array
            newBoard[ix] = cell;
            return newBoard;
        });
        return true;
    }, [boardOffset, board]);

    // Set the entire chunk.
    const setBoardState = useCallback((newCombinedState: BoardState) => {
        setBoardOffset(newCombinedState.boardOffset);
        setBoard(newCombinedState.board);
    }, []);

    return { boardState, trySetCell, setBoardState };
}
